import React from 'react';
import Select, { Props as ReactSelectProps } from 'react-select';
import { Field, FieldProps, useField } from 'formik';

import { container, error, hiddenInput } from './input-select.module.scss';
import { labelText } from './input.module.scss';
import { IOption } from '../../models/option.model';

import FormikError from './formik-error';

interface IInputSelectProps {
    className?: string;
    options: IOption[];
    name: string;
    label?: string;
    placeholder?: string;
}

const InputSelect: React.FC<IInputSelectProps> = ({
    className = '',
    name,
    options,
    label,
    placeholder,
}) => {
    const [, , fieldHelpers] = useField(name);

    const handleChange: ReactSelectProps['onChange'] = (option) => {
        const value = (option as IOption)?.value;
        if (value) {
            fieldHelpers.setValue(value);
        }
    };

    return (
        <div className={`${container} ${className}`}>
            {label && (
                <label className={labelText} htmlFor={name}>
                    {label}
                </label>
            )}
            <Field name={name}>
                {({ field }: FieldProps) => {
                    const selectedOption = options.find((option) => option.value === field.value);
                    return (
                        <>
                            <input
                                {...field}
                                className={hiddenInput}
                                tabIndex={-1}
                                autoComplete="off"
                            />
                            <Select
                                value={selectedOption}
                                defaultValue={selectedOption}
                                name={name}
                                options={options}
                                onChange={handleChange}
                                placeholder={placeholder}
                                className="custom-select"
                                classNamePrefix="custom-select"
                            />
                        </>
                    );
                }}
            </Field>
            <FormikError name={name} className={error} />
        </div>
    );
};

export default InputSelect;
