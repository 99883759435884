import * as Yup from 'yup';
import { ICompanyRegisterFormValues } from '../models/company-register.model';

export const MAX_TEXTAREA_LENGTH = 1500;
export const MAX_NAME_LENGTH = 128;
export const MAX_COMPANY_LABEL_LENGTH = 64;
export const MIN_PHONE_NUMBER_LENGTH = 7;
export const MAX_PHONE_NUMBER_LENGTH = 16;
export const MIN_POST_CODE_LENGTH = 5;
export const MAX_POST_CODE_LENGTH = 6;
export const MIN_CITY_LENGTH = 2;
export const MAX_CITY_LENGTH = 32;
export const MAX_VOIVODESHIP_LENGTH = 32;
export const MIN_TAX_NUMBER_LENGTH = 10;
export const MAX_TAX_NUMBER_LENGTH = 13;
export const MAX_FIRSTNAME_LENGTH = 16;
export const MAX_SURNAME_LENGTH = 32;
export const MAX_POSITION_LENGTH = 32;
export const MAX_VIDEO_TITLE_LENGTH = 64;
export const MIN_STREET_LENGTH = 2;
export const MAX_STREET_LENGTH = 64;

export function getValidationSchema(t: Record<string, any>) {
    return Yup.object({
        name: Yup.string()
            .max(MAX_NAME_LENGTH, t.errorMaxLength({ maxLength: MAX_NAME_LENGTH }))
            .required(t.errorRequired),
        categoryId: Yup.number().required(t.errorRequired),
        contacts: Yup.array().of(
            Yup.object({
                label: Yup.string().when(
                    [
                        'email',
                        'phone',
                        'website',
                        'street',
                        'postCode',
                        'city',
                        'taxNumber',
                        'voivodeship',
                    ],
                    {
                        is: (...values: unknown[]) => {
                            return values.some((value) => !!value || value === 0);
                        },
                        then: Yup.string()
                            .max(
                                MAX_COMPANY_LABEL_LENGTH,
                                t.errorMaxLength({ maxLength: MAX_COMPANY_LABEL_LENGTH })
                            )
                            .required(t.errorRequired),
                        otherwise: Yup.string(),
                    }
                ),
                postCode: Yup.string()
                    .min(
                        MIN_POST_CODE_LENGTH,
                        t.errorMinLength({ minLength: MIN_POST_CODE_LENGTH })
                    )
                    .max(
                        MAX_POST_CODE_LENGTH,
                        t.errorMaxLength({ maxLength: MAX_POST_CODE_LENGTH })
                    ),
                phone: Yup.string()
                    .min(
                        MIN_PHONE_NUMBER_LENGTH,
                        t.errorMinLength({ minLength: MIN_PHONE_NUMBER_LENGTH })
                    )
                    .max(
                        MAX_PHONE_NUMBER_LENGTH,
                        t.errorMaxLength({ maxLength: MAX_PHONE_NUMBER_LENGTH })
                    ),
                email: Yup.string().email(t.errorInvalidEmail),
                website: Yup.string().url(t.errorInvalidWebsiteUrl),
                street: Yup.string()
                    .min(MIN_STREET_LENGTH, t.errorMinLength({ minLength: MIN_STREET_LENGTH }))
                    .max(MAX_STREET_LENGTH, t.errorMaxLength({ maxLength: MAX_STREET_LENGTH })),
                city: Yup.string()
                    .min(MIN_CITY_LENGTH, t.errorMinLength({ minLength: MIN_CITY_LENGTH }))
                    .max(MAX_CITY_LENGTH, t.errorMaxLength({ maxLength: MAX_CITY_LENGTH })),
                voivodeship: Yup.string().max(
                    MAX_VOIVODESHIP_LENGTH,
                    t.errorMaxLength({ maxLength: MAX_VOIVODESHIP_LENGTH })
                ),
                taxNumber: Yup.string()
                    .min(
                        MIN_TAX_NUMBER_LENGTH,
                        t.errorMinLength({ minLength: MIN_TAX_NUMBER_LENGTH })
                    )
                    .max(
                        MAX_TAX_NUMBER_LENGTH,
                        t.errorMaxLength({ maxLength: MAX_TAX_NUMBER_LENGTH })
                    ),
            })
        ),
        companyProfile: Yup.string()
            .max(MAX_TEXTAREA_LENGTH, t.errorMaxLength({ maxLength: MAX_TEXTAREA_LENGTH }))
            .required(t.errorRequired),
        resources: Yup.string().max(
            MAX_TEXTAREA_LENGTH,
            t.errorMaxLength({ maxLength: MAX_TEXTAREA_LENGTH })
        ),
        otherResources: Yup.string().max(
            MAX_TEXTAREA_LENGTH,
            t.errorMaxLength({ maxLength: MAX_TEXTAREA_LENGTH })
        ),
        contactPerson: Yup.object({
            firstName: Yup.string().max(
                MAX_FIRSTNAME_LENGTH,
                t.errorMaxLength({ maxLength: MAX_FIRSTNAME_LENGTH })
            ),
            surname: Yup.string().max(
                MAX_SURNAME_LENGTH,
                t.errorMaxLength({ maxLength: MAX_SURNAME_LENGTH })
            ),
            email: Yup.string().email(t.errorInvalidEmail),
            position: Yup.string().max(
                MAX_POSITION_LENGTH,
                t.errorMaxLength({ maxLength: MAX_POSITION_LENGTH })
            ),
            phone: Yup.string()
                .min(
                    MIN_PHONE_NUMBER_LENGTH,
                    t.errorMinLength({ minLength: MIN_PHONE_NUMBER_LENGTH })
                )
                .max(
                    MAX_PHONE_NUMBER_LENGTH,
                    t.errorMaxLength({ maxLength: MAX_PHONE_NUMBER_LENGTH })
                ),
        }),
        videos: Yup.array().of(
            Yup.object({
                url: Yup.string()
                    .test({
                        name: 'Vimeo',
                        test: (value: string | undefined) =>
                            value === undefined || value.toLowerCase().includes('vimeo.com'),
                        message: t.errorVimeo,
                    })
                    .url(t.errorInvalidUrl),
                title: Yup.string().max(
                    MAX_VIDEO_TITLE_LENGTH,
                    t.errorMaxLength({ maxLength: MAX_VIDEO_TITLE_LENGTH })
                ),
            })
        ),
        consent1: Yup.bool().oneOf([true], t.errorRequiredConsent),
        consent2: Yup.bool().oneOf([true], t.errorRequiredConsent),
    });
}

export const initialValues: ICompanyRegisterFormValues = {
    name: '',
    categoryId: '',
    contacts: [
        {
            label: '',
            email: '',
            phone: '',
            website: '',
            street: '',
            postCode: '',
            city: '',
            taxNumber: '',
            voivodeship: '',
        },
    ],
    companyProfile: '',
    resources: '',
    otherResources: '',
    contactPerson: {
        firstName: '',
        surname: '',
        position: '',
        phone: '',
        email: '',
    },
    mainPhoto: '',
    otherPhotos: [],
    videos: [
        {
            url: '',
            title: '',
        },
    ],
    consent1: false,
    consent2: false,
};
