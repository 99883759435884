import React, { useState } from 'react';
import { Field, FieldProps, useField } from 'formik';

import {
    container,
    input,
    gallery,
    isMultiple,
    imageBox,
    ratio,
    deleteButton,
    error,
    plusIcon,
    addButton,
    addButtonText,
} from './input-file.module.scss';
import PlusIcon from '../../assets/images/svg/plus.svg';
import MinusIcon from '../../assets/images/svg/minus.svg';
import { IFileBase64 } from '../../models/file-base-64.model';
import useTranslations from '../../hooks/use-translations';
import { assertPromiseIsFulfilled, assertPromiseIsRejected } from '../../utils/assertions';

import Button from './button';
import Heading, { IHeadingProps } from './heading';
import BaseRatioImage from './base-ratio-image';
import FormikError from './formik-error';

interface IInputFileProps {
    className?: string;
    name: string;
    headingProps?: IHeadingProps;
    fileTypes?: string[];
    multiple?: boolean;
    maxFileCount?: number;
    maxFileSize?: number;
    onClick?(): void;
}

const InputFile: React.FC<IInputFileProps> = ({
    className = '',
    name,
    headingProps,
    multiple = false,
    maxFileCount = 1,
    maxFileSize = 5120000,
    onClick,
}) => {
    const t = useTranslations('InputFile');
    const [remainingCount, setRemainingCount] = useState(maxFileCount);
    const [, meta, helpers] = useField(name);

    const images: IFileBase64[] =
        (meta.value && (Array.isArray(meta.value) ? meta.value : [meta.value])) || [];

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files && Array.from(event.target.files);
        event.target.value = '';

        if (!files) return;

        const { validFiles, validationErrors } = validateFiles({
            files,
            maxFileCount: remainingCount,
            maxFileSize,
            fileTypes: IMAGE_FILE_TYPES,
            multiple,
            t,
        });

        const data = await Promise.allSettled(validFiles.map((file) => getBase64(file, t)));

        const filesBase64 = data.filter(assertPromiseIsFulfilled).map((result) => result.value);
        const readErrors = data.filter(assertPromiseIsRejected).map((result) => result.reason);

        setRemainingCount(maxFileCount - filesBase64.length - (multiple ? meta.value.length : 0));

        const newFieldValue = multiple ? [...meta.value, ...filesBase64] : filesBase64[0];

        helpers.setValue(newFieldValue);
        // To add errors to formik field and at the same time setting valid value
        // setError has to be wrapped in setTimeout
        setTimeout(() => {
            helpers.setError([...readErrors, ...validationErrors].join(' '));
        }, 0);
    };

    const handleClick = () => {
        if (typeof onClick === 'function') {
            onClick();
        }
    };

    const handleDelete = (toDeleteIndex: number) => {
        return () => {
            const newFieldValue = Array.isArray(meta.value)
                ? meta.value.filter((_, index) => index !== toDeleteIndex)
                : '';
            helpers.setValue(newFieldValue);
            helpers.setError('');
            setRemainingCount((prevCount) => prevCount + 1);
        };
    };

    return (
        <Field name={name}>
            {({ field }: FieldProps) => {
                return (
                    <div className={`${container} ${className} ${multiple ? isMultiple : ''}`}>
                        <input
                            className={input}
                            type="file"
                            id={name}
                            name={name}
                            accept={IMAGE_FILE_TYPES.join(',')}
                            multiple={multiple}
                            onChange={handleChange}
                            onClick={handleClick}
                            onBlur={field.onBlur}
                        />
                        {headingProps && <Heading {...headingProps} />}
                        <label htmlFor={name}>
                            <Button
                                className={addButton}
                                as="element"
                                isOutlined={true}
                                theme="dark"
                            >
                                <PlusIcon className={plusIcon} />
                                <span className={addButtonText}>{t.add}</span>
                            </Button>
                        </label>
                        {images.length > 0 && (
                            <div className={gallery}>
                                {images.map((image, index) => {
                                    if (typeof image.content !== 'string') return null;
                                    return (
                                        <div
                                            key={`image-${image.name}-${index}`}
                                            className={imageBox}
                                        >
                                            <BaseRatioImage
                                                url={image.content}
                                                ratioClass={ratio}
                                            />
                                            <button
                                                className={deleteButton}
                                                type="button"
                                                aria-label={t.delete}
                                                onClick={handleDelete(index)}
                                            >
                                                <MinusIcon />
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        <FormikError name={name} className={error} />
                    </div>
                );
            }}
        </Field>
    );
};

const IMAGE_FILE_TYPES = [
    'image/apng',
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/tiff',
    'image/webp',
    'image/x-icon',
];

async function getBase64(file: File, t: Record<string, any>) {
    return new Promise<IFileBase64>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
            resolve({
                name: file.name,
                content: reader.result,
                mimeType: file.type,
            });
        reader.onerror = () => reject(`${t.errorRead({ fileName: file.name })}`);
    });
}

interface IFilesValidationResult {
    validFiles: File[];
    validationErrors: string[];
}

interface IValidateFilesConfig {
    files: File[];
    maxFileCount: number;
    maxFileSize: number;
    fileTypes: string[];
    multiple: boolean;
    t: Record<string, any>;
}

function validateFiles({
    files,
    maxFileCount,
    maxFileSize,
    fileTypes,
    multiple,
    t,
}: IValidateFilesConfig): IFilesValidationResult {
    const validFiles: File[] = [];
    const validationErrors: string[] = [];
    let remainingCount = maxFileCount;

    files.forEach((file) => {
        if (remainingCount <= 0 && multiple) {
            validationErrors.push(t.errorMaxCount({ fileName: file.name }));
        } else if (fileTypes.length && !fileTypes.includes(file.type)) {
            validationErrors.push(t.errorFileType({ fileName: file.name }));
        } else if (file.size > maxFileSize) {
            validationErrors.push(
                t.errorMaxSize({
                    fileName: file.name,
                    maxSize: Math.round(maxFileSize / 1024 / 1000),
                })
            );
        } else {
            validFiles.push(file);
            remainingCount--;
        }
    });

    return {
        validFiles,
        validationErrors,
    };
}

export default InputFile;
