import React, { useEffect } from 'react';
import { FormikErrors, useFormikContext } from 'formik';

const ScrollToFormikError: React.FC = () => {
    const { submitCount, isValid, errors } = useFormikContext();

    useEffect(() => {
        if (isValid) return;
        const fieldErrorNames = getFormikFieldErrorNames(errors);
        if (fieldErrorNames.length <= 0) return;
        const element = document.querySelector(`[name='${fieldErrorNames[0]}']`);
        if (!element) return;
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitCount]);

    return null;
};

function getFormikFieldErrorNames(errors: FormikErrors<unknown>) {
    const transformObjectToDotNotation = (
        obj: Record<string, unknown>,
        prefix = '',
        result: string[] = []
    ) => {
        Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (!value) return;

            const nextKey = prefix ? `${prefix}.${key}` : key;
            if (assertIsObject(value)) {
                transformObjectToDotNotation(value, nextKey, result);
            } else {
                result.push(nextKey);
            }
        });

        return result;
    };

    return transformObjectToDotNotation(errors);
}

function assertIsObject(obj: unknown): obj is Record<string, unknown> {
    return typeof obj === 'object';
}

export default ScrollToFormikError;
