import React from 'react';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';

import { button, buttonIcon } from './input-array.module.scss';
import PlusIcon from '../../assets/images/svg/plus.svg';
import MinusIcon from '../../assets/images/svg/minus.svg';
import useTranslations from '../../hooks/use-translations';

import Input, { TInputProps } from '../atoms/input';

export interface IInputArrayProps {
    className?: string;
    inputsContainerClassName?: string;
    name: string;
    inputs: TInputProps[];
    inputTheme?: TInputProps['theme'];
    buttonsContent?: Partial<Record<'add' | 'delete' | 'clear', string>>;
}

const InputArray: React.FC<IInputArrayProps> = ({
    className = '',
    name,
    inputs,
    inputTheme,
    inputsContainerClassName,
    buttonsContent,
}) => {
    const t = useTranslations('InputArray');
    const { values } = useFormikContext<Record<string, any>>();

    const normalizedContent = { ...t, ...buttonsContent };

    const deleteText =
        values[name]?.length > 1 ? normalizedContent.delete : normalizedContent.clear;

    const newFields = inputs.reduce((acc, input) => {
        acc[input.name] = '';
        return acc;
    }, {} as Record<string, string>);

    const handleAdd = (helpers: FieldArrayRenderProps) => {
        return () => {
            helpers.insert(values[name].length, newFields);
        };
    };

    const handleDelete = (helpers: FieldArrayRenderProps, index: number) => {
        return () => {
            if (values[name].length <= 1) {
                helpers.replace(index, newFields);
                return;
            }
            helpers.remove(index);
        };
    };

    return (
        <FieldArray name={name}>
            {(helpers) => {
                return (
                    <div className={className}>
                        {Array.isArray(values[name]) &&
                            values[name].map((_: unknown, index: number) => {
                                return (
                                    <div
                                        key={`${name}-input-array-${index}`}
                                        className={inputsContainerClassName}
                                    >
                                        <button
                                            className={button}
                                            type="button"
                                            onClick={handleDelete(helpers, index)}
                                        >
                                            <span>{deleteText}</span>
                                            <span className={buttonIcon}>
                                                <MinusIcon />
                                            </span>
                                        </button>
                                        {inputs.map((input) => {
                                            return (
                                                <Input
                                                    theme={inputTheme}
                                                    {...input}
                                                    key={`${name}-input-${input.name}`}
                                                    name={`${name}.${index}.${input.name}`}
                                                    label={input.label}
                                                />
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        <button className={button} type="button" onClick={handleAdd(helpers)}>
                            <span className={buttonIcon}>
                                <PlusIcon />
                            </span>
                            <span>{normalizedContent.add}</span>
                        </button>
                    </div>
                );
            }}
        </FieldArray>
    );
};

export default InputArray;
