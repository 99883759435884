import React from 'react';

import { container, form } from './company-register.module.scss';
import useTranslations from '../hooks/use-translations';

import CompanyRegisterForm from '../components/organisms/company-register-form';
import MainLayout from '../layouts/main-layout';

const CompanyRegister: React.FC = () => {
    const t = useTranslations('CompanyRegister');

    return (
        <MainLayout className={container}>
            <CompanyRegisterForm
                className={form}
                headingProps={{ Tag: 'h1', children: t.title, numberOfStyle: 2 }}
            />
        </MainLayout>
    );
};

export default CompanyRegister;
