import React, { useRef } from 'react';
import { Formik, Form, FormikConfig, FormikProps } from 'formik';
import { useMutation } from 'react-query';

import {
    container,
    heading,
    form,
    group,
    background,
    inputArray,
    groups,
    disclaimer,
    contacts,
    contactPerson,
    globalErrorText,
    submitting,
    registerButton,
    highlighted,
} from './company-register-form.module.scss';
import { IOption } from '../../models/option.model';
import { ICompanyCategory } from '../../models/company-category.model';
import { ICompanyRegisterFormValues } from '../../models/company-register.model';
import {
    getValidationSchema,
    initialValues,
    MAX_TEXTAREA_LENGTH,
} from '../../formik/company-register';
import { useCompanyCategories } from '../../hooks/use-company-categories';
import { getLessImportantHeading } from '../../utils/get-less-important-heading';
import useTranslations from '../../hooks/use-translations';
import { postCompany } from '../../api-ssr/company-register';
import { handleFormikErrors, TAlterpressAxiosError } from '../../utils/get-form-errors';
import { useModalContext } from '../../contexts/modal-context';

import Heading, { IHeadingProps } from '../atoms/heading';
import Input from '../atoms/input';
import InputSelect from '../atoms/input-select';
import InputArray, { IInputArrayProps } from '../molecules/input-array';
import Checkbox from '../atoms/checkbox';
import Button from '../atoms/button';
import InputFile from '../atoms/input-file';
import ScrollToFormikError from '../atoms/scroll-to-formik-error';

interface ICompanyRegisterFormProps {
    className?: string;
    headingProps?: Omit<IHeadingProps, 'className'>;
}

const CompanyRegisterForm: React.FC<ICompanyRegisterFormProps> = ({
    className = '',
    headingProps,
}) => {
    const t = useTranslations('CompanyRegisterForm');
    const { showModal } = useModalContext();
    const formikRef = useRef<FormikProps<ICompanyRegisterFormValues>>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const companyCategories = useCompanyCategories();
    const mutation = useMutation(postCompany, {
        onSuccess: () => handleSuccess(),
        onError: (error) => {
            handleFormikErrors(formikRef.current, error as TAlterpressAxiosError, {
                network: t.errorNetwork,
                global: t.errorGlobal,
            });
        },
    });

    const categoryOptions = getCategoryOptions(companyCategories);
    const FormHeadingTag = getLessImportantHeading(headingProps?.Tag) || 'h2';

    const handleSubmit: FormikConfig<ICompanyRegisterFormValues>['onSubmit'] = (
        values,
        helpers
    ) => {
        helpers.setSubmitting(true);
        mutation.mutate(values);
    };

    const handleSuccess = () => {
        const formik = formikRef.current;
        const container = containerRef.current;

        if (!formik) return;

        formik.setSubmitting(false);
        formik.resetForm();

        if (container) {
            container.scrollIntoView(true);
        }

        showModal({
            modalProps: { title: t.successTitle, content: t.successContent },
            modalKey: 'info-modal',
        });
    };

    return (
        <div ref={containerRef} className={`${container} ${className}`}>
            {headingProps?.children && <Heading className={heading} {...headingProps} />}
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={getValidationSchema(t)}
            >
                {(formik) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const globalError = formik.errors.global;
                    return (
                        <Form className={`${form} ${formik.isSubmitting ? submitting : ''}`}>
                            <ScrollToFormikError />
                            <div className={group}>
                                <Input name="name" label={t.name} />
                                <InputSelect
                                    options={categoryOptions}
                                    name="categoryId"
                                    label={t.category}
                                    placeholder={t.categoryPlaceholder}
                                />
                            </div>
                            <InputArray
                                className={inputArray}
                                inputsContainerClassName={`${group} ${background} ${contacts}`}
                                name="contacts"
                                inputs={getContactInputs(t)}
                                inputTheme="white"
                                buttonsContent={{ add: 'Add a branch', delete: 'Delete a branch' }}
                            />
                            <div className={`${group} ${background}`}>
                                <Input
                                    as="textarea"
                                    name="companyProfile"
                                    label={t.companyProfile}
                                    theme="white"
                                    maxLength={MAX_TEXTAREA_LENGTH}
                                />
                                <Input
                                    as="textarea"
                                    name="resources"
                                    label={t.resources}
                                    theme="white"
                                    maxLength={MAX_TEXTAREA_LENGTH}
                                />
                                <Input
                                    as="textarea"
                                    name="otherResources"
                                    label={t.otherResources}
                                    theme="white"
                                    maxLength={MAX_TEXTAREA_LENGTH}
                                />
                            </div>
                            <div className={`${group} ${background} ${contactPerson}`}>
                                <Heading Tag={FormHeadingTag} numberOfStyle={5}>
                                    {t.contactPerson}
                                </Heading>
                                <Input
                                    name="contactPerson.firstName"
                                    label={t.contactPersonFirstName}
                                    theme="white"
                                />
                                <Input
                                    name="contactPerson.surname"
                                    label={t.contactPersonSurname}
                                    theme="white"
                                />
                                <Input
                                    name="contactPerson.position"
                                    label={t.contactPersonPosition}
                                    theme="white"
                                />
                                <Input
                                    name="contactPerson.phone"
                                    label={t.contactPersonPhone}
                                    type="tel"
                                    theme="white"
                                />
                                <Input
                                    name="contactPerson.email"
                                    label={t.contactPersonEmail}
                                    type="email"
                                    theme="white"
                                />
                            </div>
                            <div className={group}>
                                <InputFile
                                    name="mainPhoto"
                                    headingProps={{
                                        Tag: FormHeadingTag,
                                        children: t.photo,
                                        numberOfStyle: 5,
                                    }}
                                />
                            </div>
                            <div className={group}>
                                <InputFile
                                    name="otherPhotos"
                                    headingProps={{
                                        Tag: FormHeadingTag,
                                        children: t.otherPhotos,
                                        numberOfStyle: 5,
                                    }}
                                    multiple={true}
                                    maxFileCount={100}
                                />
                            </div>
                            <div className={groups}>
                                <div className={group}>
                                    <Heading Tag={FormHeadingTag} numberOfStyle={5}>
                                        {t.videos}{' '}
                                        <span className={highlighted}>{t.videosHighlighted}</span>
                                    </Heading>
                                </div>
                                <InputArray
                                    className={inputArray}
                                    inputsContainerClassName={`${group} ${background}`}
                                    name="videos"
                                    inputs={getVideoInputs(t)}
                                    inputTheme="white"
                                />
                            </div>
                            <div className={groups}>
                                <Checkbox
                                    className={group}
                                    name="consent1"
                                    text={t.consents[0]}
                                    isExpandable={false}
                                />
                                <Checkbox
                                    className={group}
                                    name="consent2"
                                    text={t.consents[1]}
                                    isExpandable={false}
                                />
                            </div>
                            <div className={group}>
                                {globalError && (
                                    <Heading
                                        numberOfStyle={7}
                                        isRegular
                                        theme="accent"
                                        className={globalErrorText}
                                    >
                                        {globalError}
                                    </Heading>
                                )}
                                <Button
                                    className={registerButton}
                                    theme="dark"
                                    type="submit"
                                    isDisabled={formik.isSubmitting}
                                >
                                    {t.registerButton}
                                </Button>
                            </div>
                            <p className={`${group} ${disclaimer}`}>{t.disclaimer}</p>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

function getCategoryOptions(categories: ICompanyCategory[]): IOption[] {
    return categories.map((category) => {
        return {
            label: category.name,
            value: category.categoryId,
        };
    });
}

function getContactInputs(t: Record<string, any>): IInputArrayProps['inputs'] {
    return [
        { name: 'label', type: 'text', label: t.contactLabel },
        { name: 'street', type: 'text', label: t.contactStreet },
        { name: 'postCode', type: 'text', label: t.contactPostCode },
        { name: 'city', type: 'text', label: t.contactCity },
        { name: 'voivodeship', type: 'text', label: t.contactVoivodeship },
        { name: 'taxNumber', type: 'text', label: t.contactTaxNumber },
        { name: 'phone', type: 'tel', label: t.contactPhone },
        { name: 'email', type: 'email', label: t.contactEmail },
        { name: 'website', type: 'text', label: t.contactWebsite },
    ];
}

function getVideoInputs(t: Record<string, any>): IInputArrayProps['inputs'] {
    return [
        { name: 'url', type: 'url', label: t.videoUrl },
        { name: 'title', type: 'text', label: t.videoText },
    ];
}

export default CompanyRegisterForm;
