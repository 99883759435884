// extracted by mini-css-extract-plugin
export var background = "company-register-form-module--background--53c1a";
export var contactPerson = "company-register-form-module--contact-person--dd67f";
export var contacts = "company-register-form-module--contacts--4a3b3";
export var container = "company-register-form-module--container--4ce5a";
export var disclaimer = "company-register-form-module--disclaimer--19ea9";
export var form = "company-register-form-module--form--40166";
export var globalErrorText = "company-register-form-module--global-error-text--3acb3";
export var group = "company-register-form-module--group--e8ef5";
export var groups = "company-register-form-module--groups--17f1d";
export var heading = "company-register-form-module--heading--0bf71";
export var highlighted = "company-register-form-module--highlighted--36aef";
export var inputArray = "company-register-form-module--input-array--3afd5";
export var registerButton = "company-register-form-module--register-button--f65b0";
export var submitting = "company-register-form-module--submitting--e92d7";