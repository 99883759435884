// extracted by mini-css-extract-plugin
export var addButton = "input-file-module--add-button--767e5";
export var addButtonText = "input-file-module--add-button-text--4edd5";
export var container = "input-file-module--container--b3020";
export var deleteButton = "input-file-module--delete-button--7e86f";
export var error = "input-file-module--error--d8f21";
export var gallery = "input-file-module--gallery--abca8";
export var imageBox = "input-file-module--image-box--22d08";
export var input = "input-file-module--input--fd739";
export var isMultiple = "input-file-module--is-multiple--809a7";
export var label = "input-file-module--label--b9a3e";
export var plusIcon = "input-file-module--plus-icon--7ccf4";
export var ratio = "input-file-module--ratio--7063e";