import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { IQueryAllResult } from '../models/query-all-result.model';
import { ICompanyCategory } from '../models/company-category.model';
import { getNodes } from '../utils/get-nodes';

interface IUseCompanyCategoriesQueryResult {
    allCompanyCategory: IQueryAllResult<ICompanyCategory>;
}

export const useCompanyCategories = (): ICompanyCategory[] => {
    const { locale } = usePageContext();
    const { allCompanyCategory } = useStaticQuery<IUseCompanyCategoriesQueryResult>(query);
    return useMemo(() => {
        const companyCategories = getNodes(allCompanyCategory);
        // graphql sort is case-sensitive and capital letters come first
        // client needs case-insensitive sort so second one is needed
        return [...companyCategories]
            .sort((categoryA, categoryB) => {
                return categoryA.name.localeCompare(categoryB.name, locale);
            })
            .filter((category) => {
                return category.locale === locale;
            });
    }, [allCompanyCategory, locale]);
};

const query = graphql`
    query {
        allCompanyCategory(sort: { fields: name, order: ASC }) {
            edges {
                node {
                    ...companyCategoryFields
                }
            }
        }
    }
`;
